<template>
  <modify-apply-block
    data-type="worker_certificate_pro_hire"
    ref="worker_certificate_pro_hire_p"
    :getTitle="getTitle"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    :dataFileParm="{docName: '专业技术职务聘任证书', isDoc: true}"
    block-name="专业技术职务聘任证书"
    @dataFileUpdate="dataFileUpdate"
    :init-add-form-data="initAddFormData"
    ref-name="worker_certificate_pro_hire"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerCertificateRequest
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.$store.dispatch('loadJobTitleList')
    this.workerId = this.$router.history.current.query.workerId
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['worker_certificate_pro_hire_p'].openForm) {
      this.$refs['worker_certificate_pro_hire_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    async getEdit () {
      if (this.workerId) {
        return await workerCertificateRequest.getEdit({
          type: 'pro_hire',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      return await workerCertificateRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await workerCertificateRequest.addEdit(parm)
    },
    async dataFileUpdate (parm, pageVm) {
      await workerCertificateRequest.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'pro_hire'
      }
    },
    getTitle (data) {
      return (data.hireOrg || '') + ' ' + (data.hireWorker || '') + ' ' + (data.mType || '')
    }
  },
  computed: {
    jobTitleTypes () {
      return this.$store.getters.selectDatas['job_title_type']
    },
    jobTitleList () {
      return this.$store.getters.jobTitleList
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '专业',
          key: 'hirePro'
        },
        {
          type: 'input',
          label: '聘任单位',
          key: 'hireOrg'
        },
        {
          type: 'input',
          label: '聘任职务',
          key: 'hireWorker'
        },
        {
          type: 'select',
          label: '岗位类型',
          selectDatas: this.jobTitleTypes,
          key: 'xlmc'
        },
        {
          type: 'select',
          label: '岗位等级',
          getSelectDatas: (fData) => {
            let data = []
            if (fData && fData.xlmc) {
              data = this.jobTitleList.filter(v => v.data.type === fData.xlmc).map((v) => {
                return {
                  key: v.data.title,
                  label: v.data.title
                }
              })
            }
            return data
          },
          key: 'mType'
        },
        {
          type: 'datePicker',
          label: '聘期起始日期',
          key: 'hireStartDate'
        },
        {
          type: 'datePicker',
          label: '聘期终止日期',
          key: 'hireEndDate'
        }]
        return data
      }
    }
  }
}
</script>
